import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { butaksImg, butaksdarkImg , magazadarkImg , magazaImg , aramaImg , aramadarkImg } from '../utils';
import { navLists } from '../constants';

const Navbar = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark');
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setMobileMenuOpen(false);
      }
    };
    if (mobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [mobileMenuOpen]);

  const normalizeText = (text) => {
    if (text === 'Anasayfa') return '';
    return text
      .replace(/İ/g, 'i')
      .replace(/ı/g, 'i')
      .replace(/Ş/g, 'ş')
      .replace(/ş/g, 's')
      .replace(/Ç/g, 'ç')
      .replace(/ç/g, 'c')
      .replace(/Ğ/g, 'ğ')
      .replace(/ğ/g, 'g')
      .replace(/Ö/g, 'ö')
      .replace(/ö/g, 'o')
      .replace(/Ü/g, 'ü')
      .replace(/ü/g, 'u')
      .toLowerCase()
      .replace(/ /g, '');
  };


  


  return (
    <header
      className={`w-full sm:px-10 px-2 flex justify-between items-center ${
        isSticky
          ? 'fixed top-0 left-0 right-0 z-50 bg-white/80 dark:bg-black/80 sm:backdrop-blur-md shadow-md'
          : 'bg-slate-100 dark:bg-zinc-900'
      }`}
    >
      <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'></link>
      <nav className="flex w-full screen-max-width z-40">
      <img 
          src={darkMode ? butaksdarkImg : butaksImg} // Dark mode'da farklı logo göster
          alt="BUTAX" 
          width={72} 
          height={88} 
        />
        <ul className="flex flex-1 items-center justify-center max-sm:hidden">
          {navLists.map((nav) => (
            <Link
              key={nav}
              to={`/${normalizeText(nav)}`}
              className="px-5 text-sm cursor-pointer dark:text-gray dark:hover:text-white text-gray hover:text-black transition-all"
            >
              {nav}
            </Link>
          ))}
        </ul>
        <div className="flex items-center gap-6 max-sm:justify-end max-sm:flex-1">
          <button className="relative group sm:hidden" onClick={toggleMobileMenu}>
            <div className="relative flex overflow-hidden items-center justify-center w-[18px] h-[50px] transform transition-all">
              <div className="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
                <div className="bg-black dark:bg-white h-[2px] w-7 transform transition-all duration-300 origin-left group-focus:translate-x-10"></div>
                <div className="bg-black dark:bg-white h-[2px] w-7 rounded transform transition-all duration-300 group-focus:translate-x-10 delay-75"></div>
                <div className="bg-black dark:bg-white h-[2px] w-7 transform transition-all duration-300 origin-left group-focus:translate-x-10 delay-150"></div>
                <div className="absolute items-center justify-between transform transition-all duration-500 top-2.5 -translate-x-10 group-focus:translate-x-0 flex w-0 group-focus:w-12">
                  <div className="absolute bg-black dark:bg-white h-[2px] w-5 transform transition-all duration-500 rotate-0 delay-300 group-focus:rotate-45"></div>
                  <div className="absolute bg-black dark:bg-white h-[2px] w-5 transform transition-all duration-500 -rotate-0 delay-300 group-focus:-rotate-45"></div>
                </div>
              </div>
            </div>
          </button>
          <div className="relative flex overflow-hidden items-center justify-center w-[18px] h-[50px] transform transition-all">
          <img 
              src={darkMode ? aramadarkImg : aramaImg} // Dark mode'da farklı logo göster
               alt="Arama" 
                width={72} 
                 height={88}/>
          </div>
          <div className="relative flex overflow-hidden items-center justify-center w-[18px] h-[50px] transform transition-all">
          <img 
              src={darkMode ? magazadarkImg : magazaImg} // Dark mode'da farklı logo göster
               alt="Mağaza" 
                width={72} 
                 height={88}/>
          </div>
        </div>
        <button className="dm-btn ml-5" onClick={toggleDarkMode}>
          {darkMode ? 'Aydınlık Mod' : 'Koyu Mod'}
        </button>
        {mobileMenuOpen && (
          <div
            ref={mobileMenuRef}
            className={`fixed top-0 left-0 w-full h-full bg-white dark:bg-zinc-900 transform ${
              mobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
            } transition-transform duration-300 ease-in-out sm:hidden z-40`}
          >
            <div className="flex justify-between items-center p-4">
            <img 
              src={darkMode ? butaksdarkImg : butaksImg} // Dark mode'da farklı logo göster
               alt="BUTAX" 
                width={72} 
                 height={88}/>
              <button onClick={toggleMobileMenu} className="text-3xl font-bold text-gray-600 dark:text-white">
                ×
              </button>
            </div>
            <ul className="flex flex-col items-start px-6 space-y-4">
              {navLists.map((nav) => (
                <Link
                  key={nav}
                  to={`/${normalizeText(nav)}`}
                  className="text-xl text-gray-800 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400"
                  onClick={toggleMobileMenu}
                >
                  {nav}
                </Link>
              ))}
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Navbar;