import React, { useRef } from 'react';
import { butaxappImg, frameImg, frameVideo } from '../utils';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { animateWithGsap } from '../utils/animations';

const HowItWorks = () => {
  const videoRef = useRef();

  useGSAP(() => {
    gsap.from('#chip', {
      scrollTrigger: {
        trigger: '#chip',
        start: '20% bottom'
      },
      opacity: 0,
      scale: 2,
      duration: 1,
      ease: 'power2.inOut'
    });

    animateWithGsap('.g_fadeIn', {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: 'power2.inOut'
    });
  }, []);

  return (
    <section className="common-padding">
      <div className="screen-max-width">
        <div id="chip" className="flex-center w-full mb-20">
          <img src={butaxappImg} alt="BUTAfit" width={180} height={180} />
        </div>

        <div className="flex flex-col items-center">
          <h2 className="hiw-title-butax">
            BUTAfit
            <br /> ile Yepyeni Bir Sen Başlıyor!
          </h2>

          <p className="hiw-subtitle">
            Hedeflerine ulaş, sınırları aş.
          </p>
        </div>

        <div className="mt-10 md:mt-20 mb-14 my-2">
  <div className="relative h-full flex-center">
    <div className="overflow-hidden">
      <img
        src={frameImg}
        alt="frame"
        className="bg-transparent relative z-10"
        style={{ width: '100%', height: 'auto' }} // width'i 100% yaparak frame img'sinin video img'sine göre boyutlanmasını sağlıyoruz.
      />
    </div>
    <div className="hiw-video">
      <video className="pointer-events-none" playsInline preload="none" muted autoPlay ref={videoRef}>
        <source src={frameVideo} type="video/mp4" />
      </video>
    </div>
  </div>
  <p className="text-black dark:text-gray font-semibold text-center mt-3">BUTAfit akıllı telefon uygulaması: Uygulama içi görüntü</p>
  <p className="text-black dark:text-gray font-semibold text-center">Çok yakında sizlerle</p>
</div>

        <div className="hiw-text-container">
          <div className="flex flex-1 justify-center flex-col">
            <p className="hiw-text mb-5">
              <span className="text-black dark:text-white">
                Gerçek PT'ler ve diyetisyenler
              </span>le 7/24 iletişim{''}.
            </p>

            <p className="hiw-text mb-5">
              Her seviyeye ve hedefe uygun {' '}
              <span className="text-black dark:text-white">
                kişiselleştirilmiş antrenman programları
              </span>.
            </p>

            <p className="hiw-text mb-5">
              <span className="text-black dark:text-white">
                Akıllı spor ekipmanlarıyla
              </span> entegre çalışma deneyimi{' '}.
            </p>

            <p className="hiw-text">
              BUTAX topluluğuna katıl, {' '}
              <span className="text-black dark:text-white">
                aylık spor puanında yarış, liderlik tablosunda yüksel
              </span> ve birbirinden cazip ödüller kazan!
            </p>
            <br />
            <p className="hiw-text">
              {' '}
              <span className="text-black dark:text-white">
                Kullanıcılarımızın hareketlerini gerçek zamanlı analiz eden ve geri bildirim sağlayan
              </span> bir yapay zeka antrenör.
            </p>
          </div>

          <div className="flex-1 flex justify-center flex-col">
            <p className="hiw-text">Yeni</p>
            <p className="hiw-bigtext">BUTAX-fit 0.0.1</p>
            <p className="hiw-text">Uzman Rehberliğinde yapay zeka destekli Profesyonel Koçluk, Cebinizde.</p>
            <br />
            <p className="hiw-text mb-5">
              BUTAX ile arkadaşlarını ekle, davet et, {' '}
              <span className="text-zinc-900 dark:text-white">
                hedefler belirleyin, birbirinizi motive edin.
              </span> Kazandığınız 3 aylık ücretsiz BUTAX uygulaması ile sporu daha eğlenceli hale getirin.
            </p>

            <p className="hiw-text mb-5">
              Kullanıcılarımızın yemek fotoğraflarını çekerek {' '}
              <span className="text-zinc-900 dark:text-white">
                yapay zeka ile besin değerleri hakkında bilgi alabilecekleri, kolayca kalori hesaplayabilecekleri ve sağlıklı tarif önerileri alabilecekleri
              </span> yapay zeka şefimiz BUTAX'ta.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;