import hero from "/assets/images/hero.jpeg";

export const heroImg = hero;

import butakstanitimmv from "/assets/videos/butakstanitim.mp4";
import butaksmobiletanitimmv from "/assets/videos/butaksmobiletanitim.mp4";
import highlightFirstmv from "/assets/videos/highlight-first.mp4";
import highlightSectmv from "/assets/videos/hightlight-sec.mp4";
import highlightThirdmv from "/assets/videos/hightlight-third.mp4";
import highlightFourthmv from "/assets/videos/hightlight-fourth.mp4";
import exploremv from "/assets/videos/explore.mp4";
import framemv from "/assets/videos/frame.mp4";

import butaks from "/assets/images/butaks.svg";
import butaksdark from "/assets/images/butaksdark.svg";
import butakssec from "/assets/images/butakssec.svg";
import arama from "/assets/images/arama.svg";
import aramadark from "/assets/images/aramadark.svg";
import magaza from "/assets/images/magaza.svg";
import magazadark from "/assets/images/magazadark.svg";
import watch from "/assets/images/watch.svg";
import right from "/assets/images/right.svg";
import replay from "/assets/images/replay.svg";
import play from "/assets/images/play.svg";
import pause from "/assets/images/pause.svg";

import yellow from "/assets/images/yellow.jpg";
import blue from "/assets/images/blue.jpg";
import white from "/assets/images/white.jpg";
import black from "/assets/images/black.jpg";
import explore1 from "/assets/images/explore1.png";
import explore2 from "/assets/images/explore2.jpg";
import ozellik1 from "/assets/images/geridonusum.png";
import ozellik2 from "/assets/images/vandalizm.png";
import ozellik3 from "/assets/images/kent-estetigi.png";
import ozellik4 from "/assets/images/her-yas-icin-spor.png";
import ozellik5 from "/assets/images/sporda-yapay-zeka.png";
import butaxapp from "/assets/images/BUTAfit.png";
import frame from "/assets/images/frame.png";

export const butakstanitimVideo = butakstanitimmv;
export const butaksmobiletanitimVideo = butaksmobiletanitimmv;
export const highlightFirstVideo = highlightFirstmv;
export const highlightSecondVideo = highlightSectmv;
export const highlightThirdVideo = highlightThirdmv;
export const highlightFourthVideo = highlightFourthmv;
export const exploreVideo = exploremv;
export const frameVideo = framemv;

export const butaksImg = butaks;
export const butaksdarkImg = butaksdark;
export const butakssecImg = butakssec;
export const aramaImg = arama;
export const aramadarkImg = aramadark;
export const magazaImg = magaza;
export const magazadarkImg = magazadark;
export const watchImg = watch;
export const rightImg = right;
export const replayImg = replay;
export const playImg = play;
export const pauseImg = pause;

export const yellowImg = yellow;
export const blueImg = blue;
export const whiteImg = white;
export const blackImg = black;
export const explore1Img = explore1;
export const explore2Img = explore2;
export const geridonusumImg = ozellik1;
export const vandalizmImg = ozellik2;
export const kentestetigiImg = ozellik3;
export const heryasicinsporImg = ozellik4;
export const spordayapayzekaImg = ozellik5;
export const butaxappImg = butaxapp;
export const frameImg = frame;


