import React from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"  
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

const Privacy = () => {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <section className="p-5 md:p-10">
      <div className="text-center my-10">
        <h1 className="text-3xl md:text-5xl font-semibold text-zinc-900 dark:text-white">
          Butaks Gizlilik Politikası
        </h1>
        <p className='text-zinc-900 dark:text-white text-base md:text-lg font-medium'>13 Eylül 2024 tarihinde güncellendi</p>
<p>Butaks olarak,  sizden topladığımız bilgileri nasıl kullandığımızı ve koruduğumuzu açıklamak için bu Gizlilik Politikasını oluşturduk.</p> 
<p className='text-zinc-900 md:pl-[152px] md:pr-[152px] dark:text-white text-base md:text-lg font-medium'>Bu Gizlilik Politikası'na ek olarak, kişisel verilerinizin kullanılması için izninizin istendiği<span className="font-bold"> ürünlerimizde ve belirli özelliklerimizde</span> yerleşik olarak<span className="font-bold"> veri ve gizlilik bilgileri</span> de sağlıyoruz. Ürünle ilgili<span className="font-bold"> söz konusu bilgilerin yanında</span> Butaks Veriler ve Gizlilik Simgemizi<span className="font-bold"> bulabilirsiniz</span>.
</p>

      </div>
      {[
        {
          title: 'Butaks İçin Kişisel Veriler Ne Anlama Gelir',
          id: 6,
          content: (
            <>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Butaks olarak, <span className="font-bold">temel gizlilik haklarına</span> derinden inanıyoruz ve bu hakların nerede yaşarsanız yaşayın değişmemesi gerektiğini savunuyoruz. Bu nedenle, kimliği belirli veya belirlenebilir kişilerle ilgili olan ya da Butaks tarafından bu kişilerle ilişkilendirilen tüm verileri, kişilerin nerede yaşadığına bakılmaksızın <span className="font-bold">"kişisel veri"</span> olarak kabul ediyoruz.
              </p>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Bu, adınız gibi sizi doğrudan tanımlayan verilerin yanı sıra, aygıtınızın seri numarası gibi sizi doğrudan tanımlamayan ancak makul bir şekilde kimliğinizi ortaya çıkarabilecek verilerin de kişisel veri olduğu anlamına gelir. Birleştirilmiş veriler ise, bu Gizlilik Politikası'nın amaçları doğrultusunda kişisel olmayan veriler olarak ele alınır.
              </p>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Bu Gizlilik Politikası'nda, Butaks’ın web siteleri, uygulamaları veya bire bir etkileşimler aracılığıyla (ör. telefonla ya da Butaks mağazalarını ziyaret ederek) kişisel verileri nasıl işlediği açıklanmaktadır. Ayrıca Butaks hizmetlerinde üçüncü taraf bağlantılar bulunabilir ya da üçüncü taraf uygulamaları sunabiliriz. Üçüncü tarafların kişisel verileri kullanma biçimleri, Butaks’ın Gizlilik Politikası'na tabi değildir. Üçüncü taraflarla etkileşimde bulunmadan önce gizlilik politikalarını okumanızı öneririz.
              </p>
            </>
          )
        },        
        {
          title: "Butaks'ın Kişisel Veri Kullanımı",
          id: 7,
          content: (
            <>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Butaks olarak, <span className="font-bold">kişisel verileri</span> hizmetlerimizi sunmak, işlemlerinizi işlemek, sizinle iletişim kurmak, dolandırıcılığı önlemek, güvenliği sağlamak ve yasalara uymak amacıyla kullanıyoruz. Kişisel verilerinizi, izniniz doğrultusunda başka amaçlar için de kullanabiliriz.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Kişisel verilerinizi yalnızca geçerli bir <span className="font-bold">yasal dayanağımız</span> olduğunda işleriz. Duruma göre, izniniz doğrultusunda veya tarafı olduğunuz bir sözleşmeyi yerine getirmek, güvenliğinizi sağlamak ya da yasalara uymak için kişisel verileri işleyebiliriz. Kişisel verilerinizi, haklarınızı ve beklentilerinizi göz önünde bulundurarak, <span className="font-bold">meşru menfaatlerimiz</span> doğrultusunda da kullanabiliriz.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                <span className="font-bold">Hizmetlerimizi Sunma</span>: Butaks olarak, hizmetlerimizi sunmak için gerekli olan kişisel verileri toplarız. Bu veriler, teklifleri kişiselleştirmek ve hizmetlerimizi geliştirmek gibi amaçlarla kullanılabilir. Örneğin, Butaks platformunda bir ürünü satın aldığınızda, bu işlemi tamamlamak ve ilgili hizmeti sağlamak için kişisel verilerinizi kullanırız.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                <span className="font-bold">İşlemlerinizi İşleme</span>: Satın alma işlemleriniz için adınız, ödeme bilgileriniz gibi verilerin toplanması gerekmektedir.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                <span className="font-bold">Sizinle İletişim Kurma</span>: Butaks, işlemleriniz ya da hesabınızla ilgili size ulaşmak, ürünlerimiz ve hizmetlerimizle ilgili bilgi sağlamak veya geri bildirim talep etmek amacıyla kişisel verilerinizi kullanır. Önemli bildirimler, sözleşme hükümleri ve politikalarımızdaki değişikliklerle ilgili bilgi de içerebilir.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                <span className="font-bold">Dolandırıcılığı Önleme ve Güvenlik</span>: Müşterilerimizi ve çalışanlarımızı korumak, dolandırıcılığı önlemek ve Butaks’ı güvence altına almak amacıyla kişisel veriler toplanır. Bu, olası yasa dışı içeriklerin taranması ve kontrol edilmesini de içerir.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                <span className="font-bold">Kişiselleştirme</span>: Hizmetlerinizi veya iletişimlerinizi kişiselleştirmek isterseniz, Butaks bu bilgileri size kişiselleştirilmiş deneyimler sunmak için kullanır. Gizlilik seçeneklerinizi istediğiniz zaman değiştirebilir, web sitemiz veya cihazınız üzerinden kontrol edebilirsiniz.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                <span className="font-bold">Yasalara Uygunluk</span>: Yürürlükteki yasalara uymak, vergi yükümlülüklerini yerine getirmek veya resmi taleplere yanıt vermek amacıyla kişisel veriler toplarız.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Butaks, kişisel verileri yalnızca toplanma amaçlarını yerine getirmek için gereken süre boyunca saklar. Veriler, yasaların gerektirdiği veya izin verdiği en kısa süre boyunca saklanır ve gerekli süre geçtikten sonra güvenli bir şekilde imha edilir.
              </p>
            </>
          )
        },
        {
          title: 'Kişisel Verilerin Paylaşımı',
          id: 8,
          content: (
            <>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Butaks olarak, kişisel verilerinizi <span className="font-bold">Butaks bağlı şirketleri</span>, <span className="font-bold">bizim adımıza hareket eden hizmet sağlayıcıları</span>, <span className="font-bold">iş ortaklarımız</span> veya sizin yönlendirdiğiniz kişilerle paylaşabiliriz. Butaks, kişisel verilerinizi üçüncü taraflarla pazarlama amaçları doğrultusunda paylaşmaz.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                <span className="font-bold">Hizmet Sağlayıcıları</span>: Butaks olarak, hizmetlerimizi sunarken ve ürünlerin teslimatını sağlarken, kişisel verilerin işlenmesi veya saklanması gibi belirli görevleri yerine getirmeleri için üçüncü taraf hizmet sağlayıcılarla çalışırız. Bu sağlayıcılar, kişisel verileri yalnızca talimatlarımız doğrultusunda ve işbu Gizlilik Politikası’na uygun şekilde işlemekle yükümlüdür. Paylaşılan kişisel veriler, kendi amaçları için kullanılamaz ve iş tamamlandığında silinmeleri veya iade edilmeleri gerekir.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                <span className="font-bold">İş Ortakları</span>: Butaks, belirli hizmetler veya ürün teklifleri sunmak amacıyla üçüncü taraflarla iş birliği yapabilir. Bu iş birliklerinde, kişisel verilerinizin korunmasını sağlamak için iş ortaklarımızın gerekli önlemleri almasını zorunlu kılarız.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                <span className="font-bold">Diğerleri</span>: Kişisel verilerinizi, sizin izniniz veya yönlendirmeniz doğrultusunda başka kişilerle paylaşabiliriz. Aynı zamanda, ulusal güvenlik, yasa uygulamaları ya da kamu güvenliği ile ilgili durumlarda kişisel verilerinizin ifşasının gerekli olduğunu düşünürsek bu bilgileri paylaşabiliriz. Ayrıca, yasal dayanak olduğu durumlarda, Butaks’ın şartlarını uygulamak veya kullanıcılarımızı korumak için bilgileri ifşa edebiliriz. Bir birleşme, satış veya yeniden yapılandırma durumunda da kişisel verileriniz paylaşılabilir.
              </p>
        
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Butaks, kişisel verilerinizi satmaz ve paylaşmaz. Butaks’ın kişisel verilerinizi koruma konusundaki yaklaşımı, yürürlükteki yasalarla uyumludur.
              </p>
            </>
          )
        },        
        { 
          title: "Butaks'da Kişisel Verilerin Korunması", 
          id: 4,
          content: (
            <p class="text-zinc-900 dark:text-white text-base md:text-lg font-medium">
              Butaks olarak, sizinle olan ilişkimizde gizliliğinizi önemsiyoruz ve kişisel verilerinizi korumaya kararlıyız. Kişisel verilerin ve bunları işlemenin doğasını ve olası tehditleri dikkate alarak kişisel verilerinizi korumak için idari, teknik ve fiziksel önlemlerden yararlanıyoruz. Kişisel verilerinizin güvenliğini sağlamaya yardımcı olması için söz konusu güvenlik önlemlerini sürekli olarak iyileştirmeye çalışıyoruz. Bununla birlikte, internet üzerinden hiçbir yöntem veya elektronik depolama %100 güvenli değildir. Bu nedenle, bilgilerin mutlak güvenliğini garanti edemiyoruz.
            </p>
          )
        },
        { 
          title: 'Çerezler', 
          id: 5,
          content: (
            <>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Web sitemizi ziyaret ettiğinizde, tarayıcınıza bilgileri tanıyan ve kaydeden küçük metin dosyaları olan çerezler gönderebiliriz. Bu bilgiler şunları içerebilir:
              </p>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Butaks'ın web sitelerinde, çevrimiçi hizmetlerinde, etkileşimli uygulamalarında ve reklamlarında "çerezler" ve internet işaretleri gibi teknolojiler kullanılabilir. Bu teknolojiler, dolandırıcılığı önleme ve güvenlik amaçları dahil olmak üzere müşteri davranışını daha iyi anlamamıza yardımcı olur; bize web sitelerimizin hangi bölümlerinin ziyaret edildiği ile ilgili bilgiler verir; ayrıca reklamların ve web aramalarının etkinliğini kolaylaştırır ve ölçer.
              </p>
              <ul class="list-none pl-5 py-1">
                <li className="text-zinc-900 dark:text-white text-base md:text-lg font-medium">
                  <span className="font-bold">İletişim Çerezleri</span>: Bu çerezler hataları belirlememize yardımcı olmanın yanı sıra Butaks'ın sistemlerine gelen ve giden ağ trafiğine olanak tanımak için kullanılır.
                </li>
                <li className="text-zinc-900 dark:text-white text-base md:text-lg font-medium">
                  <span className="font-bold">Kesinlikle Gerekli Olan Çerezler</span>: Bu çerezler, eriştiğiniz veya talep ettiğiniz belirli bir özelliğin veya hizmetin sağlanması için gereklidir. Örneğin web sitelerimizi uygun biçimde ve dilde görüntülememize, işlemlerinizi doğrulayıp onaylamamıza ve sepet içeriğinizi korumamıza olanak tanır.
                </li>
                <li className="text-zinc-900 dark:text-white text-base md:text-lg font-medium">
                  <span className="font-bold">Diğer Çerezler</span>: Bu çerezler, ziyaretçilerin web sitelerimizle ve çevrimiçi hizmetlerimizle nasıl etkileşimde bulunduklarını anlamamıza yardımcı olur (reklamların ve web aramalarının etkinliğini değerlendirmemize yardımcı olma dahil). Ayrıca, web'de dolaşırken yaptığınız seçimleri hatırlayarak size özelleştirilmiş bir deneyim sunarız.
                </li>
              </ul>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Eğer Butaks’ın çerez kullanımını tercih etmiyorsanız, çerezleri devre dışı bırakabileceğiniz yollar sunarız. Çerezleri devre dışı bırakmak istiyorsanız ve Safari web tarayıcısını kullanıyorsanız, Safari'nin gizlilik ayarlarında "Tüm çerezleri engelle" seçeneğini seçebilirsiniz. Farklı bir tarayıcı kullanıyorsanız çerezleri nasıl devre dışı bırakacağınızı öğrenmek için tarayıcı sağlayıcınıza danışın. Tüm çerezler devre dışı bırakılırsa, Butaks web sitesinin belirli özellikleri kullanılamayabilir.
              </p>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Çerezlere ek olarak Butaks, benzer hedeflere ulaşmamıza yardımcı olan başka teknolojiler de kullanır.
              </p>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Butaks olarak, size gönderdiğimiz bazı e-posta iletilerinde sizi web sitemizdeki içeriğe yönlendirecek "tıklanabilir URL"ler sağlayabiliriz. Bu URL'lerden birine tıkladığınızda, web sitemizdeki hedef sayfaya ulaşmadan önce ayrı bir internet sunucusundan geçersiniz. Bu tıklamaları izleyerek belirli konulara gösterilen ilgiyi belirlememize ve sizinle etkili bir şekilde iletişim kurup kurmadığımızı ölçmemize yardımcı oluruz. Bu izlemeyi tercih etmiyorsanız, e-posta iletilerindeki grafik veya metin bağlantılarını tıklamamanız gerekir.
              </p>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Butaks, bu çerezleri ve benzer teknolojileri kullanarak topladığımız verileri genellikle kişisel olmayan veriler olarak ele alır. Bununla birlikte İnternet Protokolü (IP) adresleri ya da benzer tanımlayıcılar yerel yasalar tarafından kişisel veri olarak değerlendirildiklerinde, bu tanımlayıcıları kişisel veri olarak ele alırız. Ayrıca, bu teknolojilerle toplanan kişisel olmayan verileri Butaks'ın elinde bulundurduğu diğer kişisel verilerle birleştirebiliriz. Bu şekilde birleştirilmiş verileri kişisel veri olarak ele alırız.
              </p>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Butaks’ın reklam platformu tarafından sunulan reklamlar, bulunduğunuz yere bağlı olarak farklı uygulamalarda gösterilebilir. Reklam platformumuz sizi takip etmez; yani uygulamalarımızdan toplanan kullanıcı veya aygıt verilerini hedefli reklam amacıyla üçüncü taraflardan toplanan verilerle ilişkilendirmez ve bu verileri paylaşmaz.
              </p>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Söz konusu uygulamalarda Butaks’ın reklam platformundan ilgi alanlarınıza yönelik reklamlar almak istemiyorsanız, <span className="font-bold">Kişiselleştirilmiş Reklamlar</span>'ı devre dışı bırakmayı seçebilirsiniz. Bu işlemin ardından, kimliğiniz bu tür reklam listelerinden çıkarılır. iOS veya Android cihazlarınızda kişiselleştirilmiş reklamları devre dışı bırakmak için ilgili ayarları kullanabilirsiniz. Uygulamalarda bağlama dayalı reklamlar görmeye devam edebilirsiniz.
              </p>
              <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
                Butaks’ın reklamları ve gizlilik politikası hakkında daha fazla bilgi için web sitemizi ziyaret edin.
              </p>
            </>
          )
        },        {
          title: 'Çocukların Ait Kişisel Verilerin Korunması',
          id: 6,
          content: (
          <>
          <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
          Butaks, <span className="font-bold">13 yaşın veya yargı bölgenizdeki yasalarca belirlenen yaşın altındaki kişilerin</span> kişisel verilerini korumanın öneminin farkındadır. Bu nedenle, Butaks çocuklara ait kişisel verileri güvende tutmak için ek süreçler ve koruma yöntemleri uygular.
          </p>
          <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
          Belirli Butaks hizmetlerine erişim için çocukların bir <span className="font-bold">Butaks Çocuk Kimliği</span>’ne sahip olması gerekmektedir. Çocuk Kimlikleri ebeveynler tarafından, <span className="font-bold">Yönetilen Kimlikler</span> ise çocukların eğitim kurumları tarafından oluşturulabilir.
          </p>
          <ul className="list-none pl-5 py-1">
          <li className="text-zinc-900 dark:text-white text-base md:text-lg font-medium">
          <span className="font-bold">Ebeveynler</span>: Ebeveynler, çocukları için bir hesap oluştururken, Butaks’ın çocuklara ait kişisel bilgileri nasıl işlediğini açıklayan <span className="font-bold">Aile Gizlilik Bildirimi</span>’ni incelemelidir. Ebeveynlerin, koşulları kabul etmeleri durumunda Butaks’a doğrulanabilir bir ebeveyn onayı sunmaları gerekmektedir.
          </li>
          <li className="text-zinc-900 dark:text-white text-base md:text-lg font-medium">
          <span className="font-bold">Eğitim Kurumları</span>: Butaks Eğitim Yönetimi Programı’na katılan eğitim kurumları, öğrenciler için <span className="font-bold">Yönetilen Butaks Kimlikleri</span> oluşturabilir. Bu okulların, Butaks Eğitim Yönetimi Sözleşmesi’ni ve <span className="font-bold">Yönetilen Kimlik Bildirimi</span>’ni kabul etmeleri gerekmektedir.
          </li>
          </ul>
          <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
          Bir çocuğun kişisel verilerinin uygun yetki olmaksızın toplandığını öğrenmemiz durumunda, bu veriler mümkün olan en kısa sürede silinecektir.
          </p>
          <p className="text-zinc-900 dark:text-white text-base md:text-lg font-medium py-1">
          Çocuğunuzun bilgilerinin korunması ile ilgili haklarınızı kullanmak için <span className="font-bold">Butaks Veriler ve Gizlilik</span> sayfasını ziyaret edin ve çocuğunuzun hesabında oturum açın.
          </p>
          </>
          )
          },
        { 
          title: 'Değişiklikler', 
          id: 7,
          content: (
            <p class="text-zinc-900 dark:text-white text-base md:text-lg font-medium">
              Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Herhangi bir değişiklik yaptığımızda, web sitemizde yayınlayarak sizi bilgilendirceğiz. Güncellenmiş gizlilik politikası değişikliklerin yapıldığı tarihte yürürlüğe girecektir. Web sitemizi düzenli olarak ziyaret ederek güncel politikalarımızdan haberdar olmanızı öneririz.
            </p>
          )
        },
        { 
          title: 'İletişim', 
          id: 8,
          content: (
            <><p class="text-zinc-900 dark:text-white text-base md:text-lg font-medium">
              Gizlilik Politikamızla ilgili herhangi bir sorunuz varsa, lütfen bizimle iletişime geçin:
            </p><ul class="list-none pl-5">
                <li class="text-zinc-900 dark:text-white text-base md:text-lg font-medium">E-posta: info@butaks.com</li>
                <li class="text-zinc-900 dark:text-white text-base md:text-lg font-medium">Veya iletişim kısmından bizlere ulaşabilirsiniz.</li>
              </ul></>
          )
        }
      ].map(section => (
        <div key={section.id} className="mb-5"> {/* Her Accordion için bir div eklendi */}
        <hr className="mx-auto md:w-9/12 border-gray-300 dark:border-gray-600" /> {/* Alt çizgi */}        
          <Accordion open={open === section.id} icon={<Icon id={section.id} open={open} />}>
            <AccordionHeader
              className="md:pl-[152px] md:pr-[152px] flex flex-row items-center text-left text-xl md:text-4xl text-zinc-900 hover:text-linka dark:text-white"
              onClick={() => handleOpen(section.id)}
            >
              <span className="">{section.title}</span>
            </AccordionHeader>
            <AccordionBody className="items-center md:pl-[152px] md:pr-[152px] text-base md:text-lg font-medium text-zinc-900 dark:text-white">
              {section.content}
            </AccordionBody>
          </Accordion>
          </div>
      ))}
    </section>
  );
}

export default Privacy;