import React from 'react';
import { useParams , Routes, Route } from 'react-router-dom'; // Router'ı import listesinden kaldırın

import Anasayfa from './pages/Anasayfa';
import Hakkimizda from './pages/Hakkimizda';
import Urunlerimiz from './pages/Urunlerimiz';
import MedyaMerkezi from './pages/MedyaMerkezi';
import Referanslar from './pages/Referanslar';
import Iletisim from './pages/Iletisim';
import Gizlilikpolitikasi from './pages/Gizlilikpolitikasi';
import Kullanimsartlari from './pages/Kullanimsartlari';
import Pagenotfound from './pages/Pagenotfound';


const App = () => {
  return (
    <Routes> {/* Router component'ini kaldırın */}
      <Route path="/" element={<Anasayfa />} />
      <Route path="/Hakkimizda" element={<Hakkimizda />} />
      <Route path="/Urunlerimiz" element={<Urunlerimiz />} />
      <Route path="/Medyamerkezi" element={<MedyaMerkezi />} />
      <Route path="/Referanslar" element={<Referanslar />} />
      <Route path="/Iletisim" element={<Iletisim />} />
      <Route path="*" element={<Pagenotfound />} /> 
      <Route path="/gizlilik-politikasi" element={<Gizlilikpolitikasi />} />
      <Route path="/kullanim-sartlari" element={<Kullanimsartlari />} />

    </Routes>
  );
};

export default App;
