import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Announcementbanner from '../components/Announcementbanner';
import Ha from '../components/Ha';
import { Helmet } from 'react-helmet';




const Hakkimizda = () => {
    return (
<div className="bg-white dark:bg-black flex flex-col min-h-screen">
      <Helmet>
        <title>Hakkımızda - BUTAX | Spor ve Teknoloji</title>
        <meta name="description" content="BUTAX, açık alan ve kapalı alan fitness aletleri üreten, spor ve teknolojiyi birleştiren yenilikçi bir markadır. Sporu herkes için daha erişilebilir, keyifli ve verimli hale getirmeyi hedefliyoruz." />
        <meta name="keywords" content="BUTAX, fitness, spor ekipmanları, açık alan fitness, kapalı alan fitness, açık alan fitness ekipmanları satın al," />
      </Helmet>

      {/* Navbar */}
      <Navbar />

      {/* Announcement Banner - Navbarın hemen altında */}
      
      {/* Ana içerik kısmı */}
      <main className="flex-grow overflow-y-auto">
        {/* Hakkımızda sayfası içeriği */}
        <Ha />
      </main>

      {/* Footer */}
      <Footer />
    </div>
    );
  };
  export default Hakkimizda;