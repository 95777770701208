import React, { useState } from 'react';

const Il = () => {
  const [formData, setFormData] = useState({
    adSoyad: '',
    telefon: '',
    email: '',
    konu: '',
    mesaj: ''
  });
  const [errors, setErrors] = useState({});  // State tanımlaması

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};

    if (!formData.adSoyad) {
      errors.adSoyad = "Ad Soyad alanı zorunludur.";
    }
    if (!formData.telefon) {
      errors.telefon = "Telefon numarası zorunludur.";
    }
    if (!formData.email) {
      errors.email = "Email zorunludur.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Geçerli bir email adresi giriniz.";
    }
    if (!formData.konu) {
      errors.konu = "Konu zorunludur.";
    }
    if (!formData.mesaj) {
      errors.mesaj = "Mesaj alanı boş olamaz.";
    }

    return errors;  // errors objesini döndürür
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();  // validate fonksiyonunu çağır
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);  // Hataları state'e kaydet
    } else {
      setErrors({});
      try {
        const response = await fetch('http://localhost.com:3000/gonder', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData)
        });

        if (response.ok) {
          alert('Mesaj başarıyla gönderildi!');
        } else {
          alert('Mesaj gönderilemedi. Lütfen tekrar deneyin.');
        }
      } catch (error) {
        console.error('Hata:', error);
        alert('Bir hata oluştu. Lütfen tekrar deneyin.');
      }
    }
  };

  return (
    <div>

      <div class="relative w-full h-96 mb-4 bg-slate-100 dark:bg-white shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md text-[#333]">
    <iframe class="absolute top-0 left-0 w-full h-full"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3056.6160260312763!2d32.5075584!3d39.9946854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d32f89bf751355%3A0x9e571493744bca2e!2sYenikent%2C%2026.%20Cad.%20No%3A11%2C%2006946%20Sincan%2FAnkara!5e0!3m2!1str!2str!4v1728295943295!5m2!1str!2str"
        loading="lazy" referrerpolicy="no-referrer-when-downgrade"
        frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0">
    </iframe>
      </div>

      <div className="my-10 grid sm:grid-cols-2 items-center gap-16 p-8 mx-auto max-w-4xl bg-slate-100 dark:bg-white shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md text-[#333]">
       <div>
       <div>
<h1 class="text-3xl font-semibold">Bize Ulaşın</h1>
<p class="text-sm text-gray-400 mt-3">BUTAX ile ilgili sorularınız veya işbirliği fırsatlarınız için bize ulaşmaktan çekinmeyin.</p>

<div class="mt-12">
    <h2 class="text-lg font-bold">Adres</h2>
    <ul class="mt-3">
        <li class="flex items-center">
            <div class="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill='#007bff'
                    viewBox="0 0 479.058 479.058">
                    <path
                        d="M434.146 59.882H44.912C20.146 59.882 0 80.028 0 104.794v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159L239.529 264.631 39.173 90.982a14.902 14.902 0 0 1 5.738-1.159zm0 299.411H44.912c-8.26 0-14.971-6.71-14.971-14.971V122.615l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"
                        data-original="#000000" />
                </svg>
            </div>
            <a target="blank" href="https://www.google.com/maps/place/Yenikent,+26.+Cad.+No:11,+06946+Sincan%2FAnkara/@39.9946854,32.5075584,17.25z/data=!4m15!1m8!3m7!1s0x14d3255e1c9d7ff9:0x6dbf7b062df01110!2sMustafa+Kemal,+06946+Sincan%2FAnkara!3b1!8m2!3d39.9893272!4d32.5089856!16s%2Fg%2F11byl1hbgr!3m5!1s0x14d32f89bf751355:0x9e571493744bca2e!8m2!3d39.9956531!4d32.5073886!16s%2Fg%2F11jzch470n?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D" class="text-[#007bff] text-sm ml-3">
                <small class="block">Yenikent, Mustafa Kemal Mh, 26. Cad. No:11, 06946 Sincan/Ankara</small>
                <strong>Türkiye / Ankara</strong>
            </a>
        </li>
    </ul>
    <h2 class="text-lg font-bold">Telefon</h2>
    <ul class="mt-3">
        <li class="flex items-center">
            <div class="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill='#007bff'
                    viewBox="0 0 479.058 479.058">
                    <path
                        d="M434.146 59.882H44.912C20.146 59.882 0 80.028 0 104.794v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159L239.529 264.631 39.173 90.982a14.902 14.902 0 0 1 5.738-1.159zm0 299.411H44.912c-8.26 0-14.971-6.71-14.971-14.971V122.615l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"
                        data-original="#000000" />
                </svg>
            </div>
            <a target="blank" href="tel:+905306602024" class="text-[#007bff] text-sm ml-3">
                <small class="block"></small>
                <strong>+90 530 660 20 24</strong>
            </a>
        </li>
    </ul>
    <h2 class="text-lg font-bold">Email</h2>
    <ul class="mt-3">
        <li class="flex items-center">
            <div class="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill='#007bff'
                    viewBox="0 0 479.058 479.058">
                    <path
                        d="M434.146 59.882H44.912C20.146 59.882 0 80.028 0 104.794v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159L239.529 264.631 39.173 90.982a14.902 14.902 0 0 1 5.738-1.159zm0 299.411H44.912c-8.26 0-14.971-6.71-14.971-14.971V122.615l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"
                        data-original="#000000" />
                </svg>
            </div>
            <a target="blank" href="mailto:info@butax.com" class="text-[#007bff] text-sm ml-3">
                <small class="block">Mail</small>
                <strong>info@butax.com</strong>
            </a>
        </li>
    </ul>
</div>

<div class="mt-12">
    <h2 class="text-lg font-bold">Sosyal Medya</h2>
    <ul class="flex mt-3 space-x-4">
        <li class="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
            <a href="javascript:void(0)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill='#007bff'
                    viewBox="0 0 24 24">
                    <path
                        d="M6.812 13.937H9.33v9.312c0 .414.335.75.75.75l4.007.001a.75.75 0 0 0 .75-.75v-9.312h2.387a.75.75 0 0 0 .744-.657l.498-4a.75.75 0 0 0-.744-.843h-2.885c.113-2.471-.435-3.202 1.172-3.202 1.088-.13 2.804.421 2.804-.75V.909a.75.75 0 0 0-.648-.743A26.926 26.926 0 0 0 15.071 0c-7.01 0-5.567 7.772-5.74 8.437H6.812a.75.75 0 0 0-.75.75v4c0 .414.336.75.75.75zm.75-3.999h2.518a.75.75 0 0 0 .75-.75V6.037c0-2.883 1.545-4.536 4.24-4.536.878 0 1.686.043 2.242.087v2.149c-.402.205-3.976-.884-3.976 2.697v2.755c0 .414.336.75.75.75h2.786l-.312 2.5h-2.474a.75.75 0 0 0-.75.75V22.5h-2.505v-9.312a.75.75 0 0 0-.75-.75H7.562z"
                        data-original="#000000" />
                </svg>
            </a>
        </li>
        <li class="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
            <a href="javascript:void(0)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill='#007bff'
                    viewBox="0 0 511 512">
                    <path
                        d="M111.898 160.664H15.5c-8.285 0-15 6.719-15 15V497c0 8.285 6.715 15 15 15h96.398c8.286 0 15-6.715 15-15V175.664c0-8.281-6.714-15-15-15zM96.898 482H30.5V190.664h66.398zM63.703 0C28.852 0 .5 28.352.5 63.195c0 34.852 28.352 63.2 63.203 63.2 34.848 0 63.195-28.352 63.195-63.2C126.898 28.352 98.551 0 63.703 0zm0 96.395c-18.308 0-33.203-14.891-33.203-33.2C30.5 44.891 45.395 30 63.703 30c18.305 0 33.195 14.89 33.195 33.195 0 18.309-14.89 33.2-33.195 33.2zm289.207 62.148c-22.8 0-45.273 5.496-65.398 15.777-.684-7.652-7.11-13.656-14.942-13.656h-96.406c-8.281 0-15 6.719-15 15V497c0 8.285 6.719 15 15 15h96.406c8.285 0 15-6.715 15-15V320.266c0-22.735 18.5-41.23 41.235-41.23 22.734 0 41.226 18.495 41.226 41.23V497c0 8.285 6.719 15 15 15h96.403c8.285 0 15-6.715 15-15V302.066c0-79.14-64.383-143.523-143.524-143.523zM466.434 482h-66.399V320.266c0-39.278-31.953-71.23-71.226-71.23-39.282 0-71.239 31.952-71.239 71.23V482h-66.402V190.664h66.402v11.082c0 5.77 3.309 11.027 8.512 13.524a15.01 15.01 0 0 0 15.875-1.82c20.313-16.294 44.852-24.907 70.953-24.907 62.598 0 113.524 50.926 113.524 113.523zm0 0"
                        data-original="#000000" />
                </svg>
            </a>
        </li>
        <li class="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
            <a href="javascript:void(0)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill='#007bff'
                    viewBox="0 0 24 24">
                    <path
                        d="M12 9.3a2.7 2.7 0 1 0 0 5.4 2.7 2.7 0 0 0 0-5.4Zm0-1.8a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9Zm5.85-.225a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM12 4.8c-2.227 0-2.59.006-3.626.052-.706.034-1.18.128-1.618.299a2.59 2.59 0 0 0-.972.633 2.601 2.601 0 0 0-.634.972c-.17.44-.265.913-.298 1.618C4.805 9.367 4.8 9.714 4.8 12c0 2.227.006 2.59.052 3.626.034.705.128 1.18.298 1.617.153.392.333.674.632.972.303.303.585.484.972.633.445.172.918.267 1.62.3.993.047 1.34.052 3.626.052 2.227 0 2.59-.006 3.626-.052.704-.034 1.178-.128 1.617-.298.39-.152.674-.333.972-.632.304-.303.485-.585.634-.972.171-.444.266-.918.299-1.62.047-.993.052-1.34.052-3.626 0-2.227-.006-2.59-.052-3.626-.034-.704-.128-1.18-.299-1.618a2.619 2.619 0 0 0-.633-.972 2.595 2.595 0 0 0-.972-.634c-.44-.17-.914-.265-1.618-.298-.993-.047-1.34-.052-3.626-.052ZM12 3c2.445 0 2.75.009 3.71.054.958.045 1.61.195 2.185.419A4.388 4.388 0 0 1 19.49 4.51c.457.45.812.994 1.038 1.595.222.573.373 1.227.418 2.185.042.96.054 1.265.054 3.71 0 2.445-.009 2.75-.054 3.71-.045.958-.196 1.61-.419 2.185a4.395 4.395 0 0 1-1.037 1.595 4.44 4.44 0 0 1-1.595 1.038c-.573.222-1.227.373-2.185.418-.96.042-1.265.054-3.71.054-2.445 0-2.75-.009-3.71-.054-.958-.045-1.61-.196-2.185-.419A4.402 4.402 0 0 1 4.51 19.49a4.414 4.414 0 0 1-1.037-1.595c-.224-.573-.374-1.227-.419-2.185C3.012 14.75 3 14.445 3 12c0-2.445.009-2.75.054-3.71s.195-1.61.419-2.185A4.392 4.392 0 0 1 4.51 4.51c.45-.458.994-.812 1.595-1.037.574-.224 1.226-.374 2.185-.419C9.25 3.012 9.555 3 12 3Z">
                    </path>
                </svg>
            </a>
        </li>
    </ul>
</div>
</div>
       </div>
       <form className="ml-auo space-y-4" onSubmit={handleSubmit}>
          <input 
            type='text'
            name='adSoyad'
            placeholder='Ad Soyad'
            value={formData.adSoyad}
            onChange={handleChange}
            className="w-full poppins text-white rounded-md py-2.5 px-4 border text-sm outline-[#007bff]"
          />
          {errors.konu && <span className="error-message">{errors.adSoyad}</span>}

          <input
            type='tel'
            name='telefon'
            placeholder='Telefon Numaranız'
            value={formData.telefon}
            onChange={handleChange}
            className="w-full poppins text-white rounded-md py-2.5 px-4 border text-sm outline-[#007bff]"
          />
          {errors.konu && <span className="error-message">{errors.telefon}</span>}

          <input
            type='email'
            name='email'
            placeholder='Email'
            value={formData.email}
            onChange={handleChange}
            className="w-full poppins text-white rounded-md py-2.5 px-4 border text-sm outline-[#007bff]"
          />
          {errors.konu && <span className="error-message">{errors.email}</span>}

          <input
            type='text'
            name='konu'
            placeholder='Konu'
            value={formData.konu}
            onChange={handleChange}
            className="w-full poppins text-white rounded-md py-2.5 px-4 border text-sm outline-[#007bff]"
          />
          {errors.konu && <span className="error-message">{errors.konu}</span>}

          <textarea
            name='mesaj'
            placeholder='Mesajınızı yazınız...'
            value={formData.mesaj}
            onChange={handleChange}
            rows="6"
            className="w-full poppins text-white rounded-md px-4 border text-sm pt-2.5 outline-[#007bff]"
          ></textarea>
          {errors.konu && <span className="error-message">{errors.mesaj}</span>}

          <button
            type='submit'
            className="text-white bg-[#007bff] hover:bg-blue-600 font-semibold rounded-md text-sm px-4 py-2.5 w-full"
          >
            Gönder
          </button>
        </form>
      </div>
    </div>
  );
};

export default Il;




