import React from 'react'
import { explore1Img } from '../utils';

const mainproducts = () => {

  return (
<section class="">
  <div class="">
    <div class="grid text-center sm:grid-cols-1 lg:grid-cols-3">
      <div class="overflow-hidden bg-[#fe001a]">
        <div class="p-2">
          <img src={explore1Img} loading="lazy" alt="titanium" />
          <h3 class="text-center my-4 text-3xl md:text-5xl text-black dark:text-white font-bold leading-tight">6A</h3>
        </div>
      </div>
      <div class="overflow-hidden bg-[#00c11b]">
        <div class="p-2">
          <img src={explore1Img} loading="lazy" alt="titanium" />
          <h3 class="text-center my-4 text-3xl md:text-5xl text-black dark:text-white font-bold leading-tight">8AB</h3>
        </div>
      </div>
      <div class="overflow-hidden bg-[#286ef0]">
        <div class="p-2">
          <img src={explore1Img} loading="lazy" alt="titanium" />
          <h3 class="text-center my-4 text-3xl md:text-5xl text-black dark:text-white font-bold leading-tight">12CD</h3>
        </div>
      </div>
    </div>



    <div class="pt-6 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="max-w-xl mx-auto text-center xl:max-w-5xl">
        <h2 class="text-3xl font-bold leading-tight text-black dark:text-white sm:text-4xl xl:text-5xl mb-6">We are just getting started!</h2>
        <p class="mb-4 text-black dark:text-white">We are creating a tool that helps you be more productive and efficient when building websites and webapps</p>
      </div>
      <div class="grid max-w-4xl lg:max-w-6xl grid-cols-1 mx-auto text-center gap-y-40 sm:gap-x-8 sm:grid-cols-2 lg:grid-cols-3 sm:mt-12 lg:my-10 sm:text-left">
        <div class="relative">
          <div class="absolute -inset-1">
            <div class="w-full h-full rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
          </div>
          <div class="relative overflow-hidden bg-white shadow-md rounded-xl h-full">
            <div class="p-4">
            <img src={explore1Img} loading="lazy" alt="titanium" class="rounded-xl" />
              <h3 class="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">Realtime Collaboration</h3>
              <p class="mt-6 text-base text-gray-600">Collaborate in realtime with other editors in a project. See what othe editors are doing and edit even a simple text together</p>
            </div>
          </div>
        </div>
        <div class="overflow-hidden bg-white shadow-md rounded-xl">
          <div class="p-4">
          <img src={explore1Img} loading="lazy" alt="titanium" class="rounded-xl" />
            <h3 class="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">History of Edits</h3>
            <p class="mt-6 text-base text-gray-600">Go back and forth your history of changes and restore your designs to any point in time</p>
          </div>
        </div>
        <div class="relative">
          <div class="absolute -inset-1">
            <div class="w-full h-full rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
          </div>
          <div class="relative overflow-hidden bg-white shadow-md rounded-xl h-full">
            <div class="p-4">
            <img src={explore1Img} loading="lazy" alt="titanium" class="rounded-xl" />
              <h3 class="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">Integrations</h3>
              <p class="mt-6 text-base text-gray-600">Step up your designs and workflow with integrations with your favourite tools such as mailchimp, slack, jira etc</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</section>

) } 

export default mainproducts