import React from 'react'
import { vandalizmImg } from '../utils';

const Ozellikiki = () => {

  return (
    <section className="nocommon-padding">
      <div className="">
        <div className="w-full">
          <h3 className="text-lg md:text-2xl text-black font-bold leading-tight">Uzun Ömürlü</h3>
          <h2 className="text-2xl md:text-5xl text-black font-bold leading-tight pb-10">Vandalizme ve çevre şartlarına karşı dirençli.</h2>
        </div>
        <div class="flex-container-iki w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <div class="flex-item-left">
            <p class="text-zinc-500 text-lg md:text-2xl font-bold pr-4 pb-4">Vandalizm ve zorlu hava koşullarına dayanıklı, uzun ömürlü malzemelerden üretilen ürünlerimizle, parklarınızı yıllarca kullanabilirsiniz.</p>
          </div>
          <div class="flex-item-right">
          <img class="w-full rounded-xl" src={vandalizmImg} alt="Vandalizm"/>
          </div>
        </div>
        <div className="w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <p class="text-zinc-500 text-lg md:text-2xl font-bold">Dayanıklı malzemeler ve gelişmiş üretim teknolojileri ile, ürünlerimiz uzun yıllar boyunca dayanıklılığını koruyarak, bakım ve onarım maliyetlerini minimize eder.</p>
        </div>
        <div class="flex-container-iki w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <div class="flex-item-left">
            <p class="text-zinc-500 text-lg md:text-2xl font-bold pr-4 pb-4">Parklarınızın uzun yıllar boyunca estetik ve güvenli kalmasını sağlayacak, vandalizm ve çevre şartlarına karşı dirençli, uzun ömürlü ürünler sunuyoruz.</p>
          </div>
          <div class="flex-item-right"></div>
        </div>
      </div>
    </section>

  );
} 

export default Ozellikiki;