import React from 'react';

import { Helmet } from 'react-helmet';
import Notfound from '../components/Notfound';




const Pagenotfound = () => {
    return (
      <div className="bg-black dark:bg-black flex flex-col min-h-screen">
      <Helmet>
        <title>Aradığınız Sayfa Bulunamadı - BUTAX | Spor ve Teknoloji</title>
        <meta name="description" content="BUTAX, açık alan ve kapalı alan fitness aletleri üreten, spor ve teknolojiyi birleştiren yenilikçi bir markadır. Sporu herkes için daha erişilebilir, keyifli ve verimli hale getirmeyi hedefliyoruz." />
        <meta name="keywords" content="BUTAX, fitness, spor ekipmanları, açık alan fitness, kapalı alan fitness, açık alan fitness ekipmanları satın al," />
      </Helmet>

      {/* Ana içerik kısmı */}
      <main className="flex-grow overflow-y-auto">
        <Notfound />
      </main>

    </div>
    );
  };
  export default Pagenotfound