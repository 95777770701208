import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Il from '../components/Il'

const Iletisim = () => {
    return (
      <div className="bg-white dark:bg-black">
        <Navbar />
        {/* Hakkımızda sayfası içeriği */}
        <main className="flex-grow overflow-y-auto">
        <Il />
        </main>
        {/* Hakkımızda sayfası içeriği */}
        <Footer />
      </div>
    );
  };
  export default Iletisim;