import React from 'react';
import { butaksdarkImg } from '../utils';


function Notfound() {
  return (
<section class="">
  <div className='w-full py-10 px-10 flex justify-between items-center'>
<img
          src={butaksdarkImg}
          alt="BUTAX" 
          width={108} 
          height={132} 
        />
        </div>
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
            <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500 text-white">404</h1>
            <p class="mb-4 text-3xl tracking-tight font-bold text-white md:text-4xl">Aradığın içeriğe şuan ulaşılamıyor.</p>
            <p class="mb-4 text-lg font-light text-white">Hemen ayrılma, aşağıdakilere göz atmak ister misin?</p>
            <a href="/" class="inline-flex text-white bg-zinc-600 hover:bg-zinc-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ring-zinc-200 my-4">Ana Sayfa</a>
        </div>   
    </div>
</section>
  );
}

export default Notfound;