import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import Mainproducts from '../products/Mainproducts';

const Urunlerimiz = () => {
  return (
    <div className="bg-white dark:bg-black flex flex-col min-h-screen">
      <Helmet>
        <title>Ürünlerimiz - BUTAX | Spor ve Teknoloji</title>
        <meta name="description" content="BUTAX, açık alan ve kapalı alan fitness aletleri üreten, spor ve teknolojiyi birleştiren yenilikçi bir markadır. Sporu herkes için daha erişilebilir, keyifli ve verimli hale getirmeyi hedefliyoruz." />
        <meta name="keywords" content="BUTAX, fitness, spor ekipmanları, açık alan fitness, kapalı alan fitness, açık alan fitness ekipmanları satın al," />
      </Helmet>
      <Navbar />
      {/* Hakkımızda sayfası içeriği */}
      <main className="flex-grow overflow-y-auto">
      <Mainproducts />
      </main>
      {/* Hakkımızda sayfası içeriği */}
      <Footer />
    </div>
  );
};
  export default Urunlerimiz;