import React, { useRef } from 'react'

const referanslarimiz = () => {

  return (
    
    <div class="h-full flex w-full justify-center items-center p-2">
  <div class="grid gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-4 md:p-2 xl:p-5">
    <div class="relative bg-white border rounded-lg shadow-md dark:bg-stone-950 dark:border-white transform transition duration-500 hover:scale-105">
      <div class="p-2 flex justify-center">
        <a href="#">
          <img class="rounded-md" src="/assets/images/Tübitak-referans-butaks.webp" loading="lazy"></img>
        </a>
      </div>
      <div class="px-4 pb-3">
        <div>
          <a href="#">
            <h5 class="text-xl font-semibold tracking-tight hover:text-violet-800 dark:hover:text-violet-300 text-gray-900 dark:text-white "> TUBİTAK </h5>
          </a>
          <p class="antialiased text-gray-600 dark:text-gray-400 text-sm break-all"> TÜBİTAK 2209/A PROJEMİZ <br></br> Spor ve Rekreasyon<br></br>Tesislerinin Kullanım Potansiyelini<br></br>Arttırarak Kentsel Gelişimi Destekleme </p>
        </div>
      </div>
    </div>
  </div>
</div>

 ) } 

export default referanslarimiz