
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Highlights from '../components/Highlights';
import Model from '../components/Model';
import Features from '../components/Features';
import HowItWorks from '../components/HowItWorks';
import Footer from '../components/Footer';

const Anasayfa = () => {
  return (
    <div className="bg-white dark:bg-black flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow overflow-y-auto">
      <Hero />
      <Highlights />
      {/* model yapılacak */}
      <Features />
      <HowItWorks />
      </main>
      <Footer />
      </div>

  );
};

export default Anasayfa;
