import React, { useRef } from 'react'

const Medyamerkezimiz = () => {

  return (
    <section className="nocommon-padding">
    <div className="py-20 mx-auto flex flex-wrap md:flex-row items-center justify-center">

      <div className="w-full xl:w-2/5 text-center md:text-left"> 
        <h1 className="my-4 text-3xl md:text-5xl text-black dark:text-white opacity-75 font-bold leading-tight">Medya merkezimiz 
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-pink-500 to-purple-500"> Şuan Bakımdadır </span> anlayışınız için teşekkür ederiz!
        </h1>
      </div>

    </div>
    </section>

  );
} 

export default Medyamerkezimiz;